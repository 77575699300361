import styled, { css } from 'styled-components';

import { rgba } from '~/hooks';
import {breakpoints, colors, easing} from '~/styles';

const { gray, status, secondary } = colors;

const { rubber } = easing;

const View = styled.div`
  width: 4rem;
  height: 2rem;
  display: flex;
  position: relative;
  transition: all 0.6s ${rubber};
  align-items: center;
  border-radius: 3.2rem;
  background-color: ${gray._300};

  span {
    left: 0.3rem;
    width: 1.4rem;
    height: 1.4rem;
    position: relative;
    transition: all 0.6s ${rubber};
    border-radius: 2.4rem;
    background-color: ${gray._400};
  }

  input {
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 2;
    position: absolute;
  }

  ${({ checked }) => checked
    && css`
      background-color: ${secondary};

      span {
        left: 2.3rem;
        background-color: ${gray._000};

        ${({ isProcess }) => isProcess && css`
          @media screen and (max-width: ${breakpoints.xxl}) and (min-width: ${breakpoints.xl}) {
            left: 0.85rem;
          }
        `}
      }
    `}
    
  
  ${({ isModified }) => isModified
    && css`
      background-color: ${rgba(status.error, 0.7)};

      span {
        background-color: ${gray._000};
      }
    `} 
 
`;

export default View;
