import styled, { css } from "styled-components";
import { breakpoints, colors, easing, typograph } from "~/styles";
import { ReactComponent as ChatbotAliantLogoIcon } from "~/assets/icons/chatbot-aliant-logo-icon.svg";
import { ReactComponent as ChatbotDBLCheckIcon } from "~/assets/icons/chatbot-dbl-check-icon.svg";

const { purple, gray, green, primary } = colors;

export const StyledChatbotDBLCheckIcon = styled(ChatbotDBLCheckIcon)``;

export const ChatbotBubbleMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;

  ${({ side }) =>
    side === "right" &&
    css`
      align-items: flex-end;
    `}

  .message-content {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    max-width: 75%;
    width: fit-content;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    a {
      color: blue;
    }

    ${({ side }) => {
      switch (side) {
        case "left":
          return css`
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 2px;
            background-color: ${gray._200};
          `;

        case "right":
          return css`
            border-bottom-right-radius: 2px;
            border-bottom-left-radius: 8px;
            background-color: #7436b0;
            color: #fff;
          `;
      }
    }}
  }

  .message-timestamp {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0 4px;
    width: fit-content;

    p {
      font-size: 12px;
      font-weight: 400px;
      line-height: 16px;
      color: ${gray._500};
    }
  }
`;

export const InverseMinusSquareIcon = styled.div`
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 6px;
  position: relative;

  &::after {
    content: "";
    background-color: ${purple._400};
    width: 10px;
    height: 2px;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledChatbotAliantLogoIcon = styled(ChatbotAliantLogoIcon)``;

export const ChatbotWindow = styled.div`
  position: fixed;
  bottom: 50px;
  z-index: 2;
  background-color: #fff;
  border: 2px solid #7436b0;
  border-radius: 8px;
  overflow: hidden;
  opacity: 0;
  transition: 0.3s opacity ease-in-out;
  display: flex;
  flex-direction: column;

  ${({ minimized }) => {
    if (minimized) {
      return css`
        width: 75vw;
        opacity: 1;
        height: 75vh;
        right: 50px;

        @media screen and (min-width: ${breakpoints.md}) {
          width: 439px;
        }
      `;
    }

    return css`
      opacity: 0;
      height: 0px;
      width: 0px;
      right: -1rem;
    `;
  }}

  .chatbot-window-header {
    width: 100%;
    height: 58px;
    flex-shrink: 0;
    padding: 16px;
    background-color: ${purple._400};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    ${StyledChatbotAliantLogoIcon} {
      flex-shrink: 0;
    }

    &-title {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: flex-start;

      h1,
      h2 {
        color: #fff;
        font-size: 12px;
        line-height: 16px;
      }

      h1 {
        font-weight: 700;
        margin-bottom: 2px;
      }

      h2 {
        font-weight: 400;
      }
    }

    &-actions {
      display: flex;
      flex-shrink: 0;
      flex-direction: row;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .chatbot-window-content {
    width: 100%;
    height: calc(100% - 158px);
    padding: 12px 0;
    position: relative;

    &-loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fbfbfbb0;
    }

    &-hint {
      padding-bottom: 12px;

      p {
        text-align: center;
        width: 100%;
        font-size: 11px;
        font-weight: 400;
        color: ${gray._500};
      }
    }

    &-messages {
      display: flex;
      flex-direction: column-reverse;
      gap: 16px 0;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100% - 13px);
      padding: 0 16px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${gray._400};
        border-radius: 8px;
      }
    }

    &-close-dialog {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fbfbfbb0;
      opacity: 0;
      width: 0px;
      height: 0px;

      &.show {
        opacity: 1;
        transition: 0.15s opacity ease-in-out;
        height: calc(100% + 100px);
        width: 100%;
      }

      .close-dialog-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 85%;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 16px 0;
        padding: 22px 18px;
        box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
          0 8px 10px -6px rgb(0 0 0 / 0.1);

        &-question {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 0 16px;
          width: 100%;

          p {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
          }
        }

        &-actions {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 0 8px;

          button {
            height: 36px;
            width: 104px;
            border-radius: 4px;
            background-color: ${primary._000};
            padding: 0 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid ${primary._000};
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;

            &.outline {
              background-color: transparent;
              color: ${primary._000};
            }
          }
        }
      }
    }
  }

  .chatbot-window-actions {
    width: 100%;
    height: 100px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0 8px;
    padding: 16px;
    border-top: 1px solid ${gray._200};

    &-input-group {
      flex-grow: 1;
      border: 1px solid ${gray._400};
      height: 52px;
      overflow: hidden;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0 8px;
      padding: 8px 12px;

      & > button {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      input {
        flex-grow: 1;
        height: 100%;
        font-size: 16px;
      }
    }

    & > button {
      flex-shrink: 0;
      width: 52px;
      height: 52px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${green._500};
    }
  }
`;

export const ChatbotContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 999;
`;
