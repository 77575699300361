import React, { useCallback, useEffect, useState } from "react";
import BubbleButton from "./BubbleButton";
import {
  ChatbotContainer,
  ChatbotWindow,
  InverseMinusSquareIcon,
  ChatbotBubbleMessageContainer,
  StyledChatbotAliantLogoIcon,
  StyledChatbotDBLCheckIcon,
} from "./styles";
import { X, Send, AlertOctagon } from "react-feather";
import { format } from "date-fns";
import DateFnsPT from "date-fns/locale/pt-BR";
import DateFnsES from "date-fns/locale/es";
import DateFnsEN from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import emitter from "~/hooks/useEmitter";
import { useApi } from "~/hooks";
import { useKeycloak } from "@react-keycloak/web";
import SimpleLoading from "../SimpleLoading";
import { useAlert } from "react-alert";

const ChatbotBubbleMessage = ({ side, content, createdAt, t }) => {
  const { i18n } = useTranslation();

  const getLocale = () => {
    switch (i18n.language) {
      case "en":
        return DateFnsEN;
      case "es":
        return DateFnsES;
      case "pt":
      default:
        return DateFnsPT;
    }
  };

  const convertMarkdownToHtml = (markdown) => {
    let html = markdown;

    // Converter títulos
    html = html.replace(/^### (.*$)/gim, "<h3>$1</h3>");
    html = html.replace(/^## (.*$)/gim, "<h2>$1</h2>");
    html = html.replace(/^# (.*$)/gim, "<h1>$1</h1>");

    // Converter texto em negrito
    html = html.replace(/\*\*(.*)\*\*/gim, "<b>$1</b>");

    // Converter texto em itálico
    html = html.replace(/\*(.*)\*/gim, "<i>$1</i>");

    // Converter links
    html = html.replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>");

    // Converter quebra de linha
    html = html.replace(/\n/gim, "<br>");

    return html.trim(); // Remove espaços extras
  };

  return (
    <ChatbotBubbleMessageContainer side={side}>
      <div
        className="message-content"
        dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(content) }}
      />
      <div className="message-timestamp">
        {/* {createdAt && (
          <p>
            {format(createdAt, "EEEE, HH:mm", {
              locale: getLocale(),
            })}
          </p>
        )} */}

        {side === "right" && <StyledChatbotDBLCheckIcon />}
      </div>
    </ChatbotBubbleMessageContainer>
  );
};

const Chatbot = () => {
  const MAX_CHARACTERS = 256;
  const toast = useAlert();
  const { keycloak } = useKeycloak();
  const api = useApi();
  const [opened, setOpened] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [submittingQuestionLoading, setSubmittingQuestionLoading] =
    useState(false);
  const [question, setQuestion] = useState("");
  const [minimized, setMinimized] = useState(true);
  const [wantToClose, setWantToClose] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inProgressMessage, setInProgressMessage] = useState(null);

  const { t } = useTranslation("components");

  const toggleMinimizeChatbot = () => {
    setMinimized((prevState) => !prevState);
  };

  const showCloseDialog = () => {
    setWantToClose(true);
  };

  const handleConfirmClose = async () => {
    setWantToClose(false);
    setOpened(false);
    setMessages([]);
    setQuestion("");
    setInProgressMessage(null);

    try {
      await api().put(`/services/chatbot`);
    } catch (e) {
      console.error(e);
    }
  };

  const renderMessages = useCallback(() => {
    const arr = [
      <ChatbotBubbleMessage
        side={"left"}
        content={t("chatbot.greeting")}
        createdAt={new Date()}
        t={t}
      />,
    ];

    for (const message of messages) {
      arr.push(
        <ChatbotBubbleMessage
          side={message.side}
          content={message.content}
          createdAt={message.createdAt}
          t={t}
        />
      );
    }

    if (inProgressMessage) {
      arr.push(
        <ChatbotBubbleMessage
          side={"left"}
          content={inProgressMessage}
          createdAt={null}
          t={t}
        />
      );
    }

    return arr.reverse();
  }, [messages, inProgressMessage]);

  const SSEListener = () => {
    const stream = new EventSource(
      `${process.env.REACT_APP_API_URL}/api/v2/services/chatbot/response-listener?accessToken=${keycloak.token}`
    );

    stream.onopen = () => {
      console.log("connected");
    };

    stream.onmessage = (event) => {
      const chunk = JSON.parse(event.data);

      if (chunk?.done) {
        setMessages((prevState) => {
          prevState.push({
            side: "left",
            content: chunk?.message,
            createdAt: new Date(),
          });

          return prevState;
        });

        setInProgressMessage(null);
        stream.close();

        return;
      }

      setInProgressMessage(chunk?.message);
    };

    stream.onerror = (e) => {
      console.error(e);
      toast.error(t("chatbot.error"));
    };

    return stream;
  };

  const handleSubmitQuestion = async () => {
    setSubmittingQuestionLoading(true);

    const tmpQuestion = question;

    try {
      await api().post(`/services/chatbot/send-question`, {
        question: tmpQuestion,
      });

      setMessages((prevState) => {
        prevState.push({
          side: "right",
          content: tmpQuestion,
          createdAt: new Date(),
        });

        return prevState;
      });

      setQuestion("");

      SSEListener();
    } catch (e) {
      console.error(e);
      toast.error(t("chatbot.error"));
    } finally {
      setSubmittingQuestionLoading(false);
    }
  };

  const handleCallAliane = async () => {
    setOpened(true);
    setFetchLoading(true);

    try {
      const { data } = await api().get("/services/chatbot");

      setMessages(data.messages);
    } catch (e) {
      console.error(e);
      toast.error(t("chatbot.error"));
    } finally {
      setFetchLoading(false);
    }
  };

  useEffect(() => {
    emitter.on("callAliane", handleCallAliane);

    return () => {
      emitter.off("callAliane", handleCallAliane);
    };
  }, []);

  return (
    <>
      {opened && (
        <ChatbotContainer>
          <ChatbotWindow minimized={minimized}>
            <div className="chatbot-window-header">
              <StyledChatbotAliantLogoIcon />
              <div className="chatbot-window-header-title">
                <h1>{t("chatbot.header.title")}</h1>
                <h2>{t("chatbot.header.subtitle")}</h2>
              </div>
              <div className="chatbot-window-header-actions">
                <button
                  type="button"
                  onClick={toggleMinimizeChatbot}
                  disabled={submittingQuestionLoading}
                >
                  <InverseMinusSquareIcon />
                </button>
                <button
                  type="button"
                  onClick={showCloseDialog}
                  disabled={submittingQuestionLoading}
                >
                  <X color="#FFF" />
                </button>
              </div>
            </div>
            <div className="chatbot-window-content">
              {fetchLoading && (
                <div className="chatbot-window-content-loading">
                  <SimpleLoading />
                </div>
              )}
              <div className="chatbot-window-content-hint">
                <p>{t("chatbot.content.recordWarning")}</p>
              </div>
              <div className="chatbot-window-content-messages">
                {renderMessages()}
              </div>
              <div
                className={`chatbot-window-content-close-dialog ${
                  wantToClose && "show"
                }`}
              >
                <div className="close-dialog-content">
                  <div className="close-dialog-content-question">
                    <AlertOctagon color="#FFB900" size={20} />
                    <p>{t("chatbot.content.closeConfirmation")}</p>
                  </div>
                  <div className="close-dialog-content-actions">
                    <button
                      type="button"
                      className="outline"
                      onClick={() => setWantToClose(false)}
                    >
                      {t("chatbot.content.back")}
                    </button>
                    <button type="button" onClick={handleConfirmClose}>
                      {t("chatbot.content.close")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="chatbot-window-actions">
              <div className="chatbot-window-actions-input-group">
                <input
                  type="text"
                  placeholder={t("chatbot.actions.inputPlaceholder")}
                  disabled={submittingQuestionLoading}
                  value={question}
                  onChange={(e) => {
                    const input = e.target.value.replace(/<\/?[^>]+(>|$)/g, "");

                    if (input.length <= MAX_CHARACTERS) {
                      setQuestion(input);
                    }
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleSubmitQuestion();
                    }
                  }}
                />
              </div>
              <button
                type="button"
                onClick={handleSubmitQuestion}
                disabled={submittingQuestionLoading}
              >
                <Send color="#FFF" size={18} />
              </button>
            </div>
          </ChatbotWindow>
          <BubbleButton
            toggleMinimizeChatbot={toggleMinimizeChatbot}
            minimized={minimized}
          />
        </ChatbotContainer>
      )}
    </>
  );
};

Chatbot.displayName = "Chatbot";

export default Chatbot;
