import styled, { css } from "styled-components";
import { ReactComponent as ChatbotBubbleIcon } from "~/assets/icons/chatbot-bubble-icon.svg";
import { ReactComponent as ChatbotAliantLogoIcon } from "~/assets/icons/chatbot-aliant-logo-icon.svg";

export const StyledChatbotBubbleIcon = styled(ChatbotBubbleIcon)``;
export const StyledChatbotAliantLogoIcon = styled(ChatbotAliantLogoIcon)``;

export const ToggleButtonContainer = styled.button`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100px;
  width: 100px;

  .toggle-button-base-icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .toggle-button-minimized-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    transition: 0.3s opacity ease-in-out;

    ${({ minimized }) =>
      minimized &&
      css`
        opacity: 0;
      `}
  }
`;
