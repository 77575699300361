import React from "react";

import {
  ToggleButtonContainer,
  StyledChatbotAliantLogoIcon,
  StyledChatbotBubbleIcon,
} from "./styles";

const BubbleButton = ({ minimized, toggleMinimizeChatbot }) => {
  return (
    <ToggleButtonContainer
      onClick={toggleMinimizeChatbot}
      minimized={minimized}
    >
      <div className="toggle-button-base-icon">
        <StyledChatbotBubbleIcon />
      </div>
      <div className="toggle-button-minimized-icon">
        <StyledChatbotAliantLogoIcon />
      </div>
    </ToggleButtonContainer>
  );
};

BubbleButton.displayName = "BubbleButton";

export default BubbleButton;
