import React, {useCallback, useEffect, useRef, useState} from 'react';
import { useParams } from 'react-router-dom';

import {
  any, bool, number, string,
} from 'prop-types';

import {Input, Modal, P2, TableRow} from '~/components';
import {formatErrorMessage, masked} from '~/hooks';
import useToggleColumn from '~/hooks/useToggleColumn';
import { useApi } from "~/hooks";

import { useResultData } from '../../resultContext';
import {useSelectRisks} from "~/views/Search/Response/Diligence2/services/selectRisksContext";
import {ModalView} from "~/views/Search/Response/Diligence2/styles";
import {Form} from "@unform/web";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {error} from "~/services";
import breakpoints from "~/styles/breakpoints";

const AssociatedRiskSource = ({
  id,
  razaoSocial,
  cnpj,
  risk,
  status,
  active,
  marked: receivedMarked,
  themeID,
  compositionID,
  riskSourceID,
  reason,
}) => {
  const { id: processID } = useParams();
  const [imMarked, setImMarked] = useState(receivedMarked);
  const { seem, mutateEverything } = useResultData();

  const api = useApi();

  const { toggleModals, generateToggleColumn } = useToggleColumn({
    seem,
    processID,
    onSucceed: mutateEverything,
  });
  
  const { selectedRisks, setSelectedRisks } = useSelectRisks();
  const [ isChecked, setIsChecked ] = useState(false);
  
  const handleCheck = useCallback((value, evt) => {
      if (evt)
        setSelectedRisks([...selectedRisks, value])
      else
        setSelectedRisks(selectedRisks.filter((item) => item !== value));
    },
    [ isChecked, selectedRisks, setSelectedRisks ],
  );
  
  const checkboxColumn  = {
    type: 'CHECKALL',
    payload: {
      value: {value: riskSourceID, state: isChecked},
      action: handleCheck,
    },
  };

  useEffect(() => {
    setIsChecked(selectedRisks.includes(riskSourceID))
  }, [selectedRisks]);
  

  return (
    <>
      <TableRow makeItABlockOn={breakpoints.xl} columns={[
        checkboxColumn,
        { type: 'TEXT', payload: razaoSocial || '-' },
        { type: 'DOCUMENT', payload: masked(cnpj) || '-' },
        {
          payload: {
            percentage: Math.round(risk?.risk) || 0,
            impact_id: risk?.probabilidade_id_custom || risk?.probabilidade_id || 1,
          },
          type: 'PROGRESS_BAR',
        },
        generateToggleColumn(active, themeID, riskSourceID, reason, { marginLeft: "15px" }, true),
        { payload: status || 'Não informado', type: 'FLAG' },
        {
          type: 'MARKED',
          payload: {
            active: imMarked,
            action: {
              toggle () {
                const marked = ! imMarked;

                setImMarked(marked);

                api().put(`/process/${processID}/theme/${themeID}/source/${compositionID}`, { marked })
                  .catch(() => {
                    setImMarked(! marked);
                  });
              },
            },
          },
        },
        {
          payload: `/pesquisa/diligencia/${id}`,
          type: 'LINK',
        },
      ]}
      />
      {toggleModals}
    </>
  );
};

AssociatedRiskSource.propTypes = {
  id: number.isRequired,
  razaoSocial: string.isRequired,
  cnpj: string.isRequired,
  status: string,
  active: bool,
  marked: bool,
  themeID: number.isRequired,
  compositionID: number.isRequired,
  riskSourceID: number.isRequired,
  reason: string,
  // eslint-disable-next-line react/forbid-prop-types
  risk: any,
};

AssociatedRiskSource.defaultProps = {
  status: null,
  reason: null,
  active: false,
  marked: false,
  risk: null,
};

export default AssociatedRiskSource;
