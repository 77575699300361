import React, { useEffect, useRef, useState } from "react";
import {
  Bell,
  Globe,
  LogOut,
  ChevronDown,
  Menu,
  X,
  HelpCircle,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, Outlet } from "react-router-dom";

import { bool } from "prop-types";
import styled from "styled-components";

import LogoWhite from "~/assets/logo-white.svg";
import { Main, P2, Notifications } from "~/components";
import { reduce, useOutsideClick } from "~/hooks";
import useNotifications from "~/hooks/useNotifications";
import { useAuth, useMe } from "~/services";
import { colors, typograph } from "~/styles";
import { Profile } from "~/views/Administration/utils/index";

import { routes } from "./routes";
import { Item, Logo, View, Navigate, Dropdown, UserProfile } from "./styles";
import emitter from "~/hooks/useEmitter";

const Badge = styled.span`
  position: absolute;
  background-color: ${colors.status.error};
  color: ${colors.gray._000};
  font-size: ${typograph.size.s1};
  padding: 2px 6px;
  top: -10px;
  right: -10px;
  border-radius: 10px;
`;

const Head = ({ isAuthenticated }) => {
  const { logout } = useAuth();
  const ref = useRef(null);
  const { user, group, newGroup } = useMe();
  const { search: params } = useLocation();
  const navigateRef = useRef(null);

  const { t, i18n } = useTranslation("routes");
  const { notificationCount } = useNotifications();

  const [profile, onProfile] = useState(false);
  const [language, onLanguage] = useState(i18n?.language);
  const [showLanguage, onShowLanguage] = useState(false);
  const [showNotifications, onShowNotifications] = useState(false);
  const [showMenu, onShowMenu] = useState(false);

  useOutsideClick(ref, () => {
    if (profile) onProfile(!profile);
  });

  const languages = [
    { key: "en", label: "EN" },
    { key: "pt", label: "PT" },
    { key: "es", label: "ES" },
  ];

  useEffect(() => {
    if (user && Object.entries(group).length === 0) {
      newGroup({
        value: user?.hierarquia[0]?.id,
        label: user?.hierarquia[0]?.cliente,
      });
    }
  }, [user]);

  const isActive = (query) => (params === query ? "active" : "");

  const onCallAliane = () => {
    /*const url = process.env.REACT_APP_ZAMMAD_URL;

    if (!url) {
      console.error("[err] ZAMMAD URL not found");
      return;
    }

    window.open(url, "_blank");*/
    emitter.emit("callAliane");
  };

  const onCallFileManager = () => {
    const url = process.env.REACT_APP_FILE_MANAGER_URL;

    if (!url) {
      console.error("[err] FILE MANAGER URL not found");
      return;
    }

    window.open(url, "_blank");
  };

  return (
    <Main>
      <View ref={ref} isAuthenticated={isAuthenticated} isMenuOpen={showMenu}>
        <Logo to="/">
          <img src={LogoWhite} alt="" />
        </Logo>

        {isAuthenticated && (
          <>
            <Navigate ref={navigateRef}>
              {routes(t, user?.isAdminICTS()).map(
                ({ type, path, route, label, items, noVisualizer }) => {
                  switch (type) {
                    case "LINK":
                      return user?.isVisualizador() && noVisualizer ? null : (
                        <Item
                          as="li"
                          key={route}
                          onClick={() => onShowMenu(false)}
                        >
                          <NavLink to={route} end>
                            {label}
                          </NavLink>
                        </Item>
                      );

                    case "DROPDOWN":
                      return user?.isVisualizador() && noVisualizer ? null : (
                        <Item
                          as="button"
                          key={path}
                          active={!!items.find(({ query }) => params === query)}
                          onClick={() => onShowMenu(!showMenu)}
                        >
                          <li role="presentation">
                            {label}
                            <ChevronDown size="1.2rem" />
                          </li>
                          <Dropdown onClick={() => onShowMenu(false)}>
                            {items.map(
                              ({ query, name, masterOnly, noVisualizer }) => {
                                if (!user?.isMaster()) {
                                  if (user?.isVisualizador()) {
                                    return masterOnly || noVisualizer ? (
                                      ""
                                    ) : (
                                      <P2
                                        as={NavLink}
                                        to={{ pathname: path, search: query }}
                                        key={query}
                                        activeClassName={isActive(query)}
                                      >
                                        {name}
                                      </P2>
                                    );
                                  }

                                  return masterOnly ? (
                                    ""
                                  ) : (
                                    <P2
                                      as={NavLink}
                                      to={{ pathname: path, search: query }}
                                      key={query}
                                      activeClassName={isActive(query)}
                                    >
                                      {name}
                                    </P2>
                                  );
                                }

                                return (
                                  <P2
                                    as={NavLink}
                                    to={{ pathname: path, search: query }}
                                    key={query}
                                    activeClassName={isActive(query)}
                                    onClick={(e) => e.target.blur()}
                                  >
                                    {name}
                                  </P2>
                                );
                              }
                            )}
                          </Dropdown>
                        </Item>
                      );

                    default:
                      break;
                  }

                  return null;
                }
              )}
              <span role="presentation" onClick={() => onShowMenu(false)} />
            </Navigate>
            <UserProfile>
              <button type="button" style={{ position: "relative" }}>
                <HelpCircle size="2.0rem" />
                <Dropdown>
                  <P2 onClick={onCallAliane}>{t("actions.help")}</P2>
                  <P2 onClick={onCallFileManager}>
                    {t("actions.file-manager")}
                  </P2>
                </Dropdown>
              </button>
              <button
                style={{ position: "relative" }}
                type="button"
                onClick={() => onShowNotifications(!showNotifications)}
              >
                <Bell size="2.0rem" />
                {notificationCount > 0 && <Badge>{notificationCount}</Badge>}
              </button>
              <div className="divider" />
              <button type="button" className="profile">
                <div className="profile-container">
                  <Profile
                    avatar={user?.usuario?.avatar || user?.usuario?.nome}
                    hasImage={!!user?.usuario?.avatar}
                  />
                  <div className="whoami">
                    <P2 isBold title={user?.usuario.nome}>
                      {user ? reduce(user?.usuario.nome, 16) : ""}
                    </P2>
                    <P2 isBold title={group.label}>
                      {group ? reduce(group.label, 16) : ""}
                    </P2>
                  </div>
                  <ChevronDown size="2.0rem" />
                </div>
                <Dropdown type="profile">
                  <P2 onClick={() => onShowLanguage(!showLanguage)}>
                    <span>
                      <Globe />
                    </span>
                    {t("actions.language")}
                    <ChevronDown />
                  </P2>
                  <P2 className={showLanguage ? "active" : undefined}>
                    {languages.map((item) => (
                      <p
                        key={item.key}
                        role="presentation"
                        onClick={() => {
                          onLanguage(item.key);
                          i18n.changeLanguage(item.key);
                        }}
                        className={item.key === language ? "active" : undefined}
                      >
                        {item.label}
                      </p>
                    ))}
                  </P2>
                  <P2 as="button" onClick={() => logout()}>
                    <span>
                      <LogOut />
                    </span>
                    {t("actions.logout")}
                  </P2>
                </Dropdown>
              </button>
              <button
                type="button"
                onClick={() => onShowMenu(!showMenu)}
                className="toggle-menu"
              >
                {showMenu ? <X size="2.0rem" /> : <Menu size="2.0rem" />}
              </button>
              <Notifications
                show={showNotifications}
                onShow={onShowNotifications}
              />
            </UserProfile>
          </>
        )}
      </View>
      <Outlet />
    </Main>
  );
};

Head.propTypes = {
  isAuthenticated: bool,
};

Head.defaultProps = {
  isAuthenticated: false,
};

export default Head;
