import React from 'react';

import { func, bool } from 'prop-types';

import View from './styles';

const Toggle = ({
  checked,
  onChecked,
  isModified,
  disabled,
  isProcess,
  ...rest
}) => (
  <View disabled={disabled}  checked={checked} isModified={isModified} isProcess={isProcess} {...rest}>
    <span />

    <input
      disabled={disabled}
      type="checkbox"
      checked={checked}
      onChange={({ target }) => {
        onChecked(target.checked);
      }}
    />
  </View>
);

Toggle.propTypes = {
  disabled:bool,
  checked: bool,
  onChecked: func,
  isModified: bool,
  isProcess: bool,
};

Toggle.defaultProps = {
  disabled: false,
  checked: false,
  onChecked: () => {},
  isModified: false,
  isProcess: false,
};

export default Toggle;
